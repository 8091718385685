<template>
    <ResumeComponent></ResumeComponent>
</template>

<script>
    import ResumeComponent from '../components/ResumeComponent'

    export default {
        name: 'ResumeView',
        components: {
            ResumeComponent
        }
    }
</script>