<template>
    <DMComponent></DMComponent>
</template>

<script>
    import DMComponent from '../components/DMComponent'

    export default {
        name: 'DMView',
        components: {
            DMComponent
        }
    }
</script>