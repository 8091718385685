<template>
    <AdminComponent></AdminComponent>
</template>

<script>
    import AdminComponent from '../components/AdminComponent'

    export default {
        name: 'AdminView',
        components: {
            AdminComponent
        }
    }
</script>