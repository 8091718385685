<template>
    <InitiativeRoller></InitiativeRoller>
</template>

<script>
    import InitiativeRoller from '../components/InitiativeRoller'

    export default {
        name: 'InitiativeView',

        components: {
            InitiativeRoller
        },
    }</script>
