<template>
    <div>
        <v-app-bar dark color="secondary">
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-img alt="ME!"
                   class="shrink mr-2"
                   contain
                   src="@/assets/MeFatterTransparent.png"
                   transition="scale-transition"
                   width="40" />

            <v-spacer></v-spacer>

            <div>
                <div v-if="!userSignedIn" id="googleSigninButton"></div>
                <v-btn color="primary" @click="signOutConfirm" elevation="2" rounded x-small v-if="userSignedIn">Sign Out of Google</v-btn>
            </div>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list dense nav>
                <v-list-item v-for="link in links" :key="link.text" :to="link.route" :disabled="link.disabled">
                    <v-list-item-icon>
                        <v-icon>{{link.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{link.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list dense nav v-for="group in linkGroups" :key="group.text">
                <v-subheader>{{group.text}}</v-subheader>
                <v-list-item v-for="link in group.links" :key="link.text" :to="link.route" :disabled="group.disabled">
                    <v-list-item-icon>
                        <v-icon>{{link.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{link.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-dialog v-model="generalDialog.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey">
                    {{generalDialog.title}}
                </v-card-title>

                <v-card-text>
                    {{generalDialog.text}}
                    <div v-html="generalDialog.html"></div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-end">
                    <v-btn v-if="generalDialog.buttonText && generalDialog.buttonType" @click="generalDialogFunction">{{generalDialog.buttonText}}</v-btn>
                    <v-btn color="secondary"
                           @click="generalDialog.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'NavbarSection',
        data() {
            return {
                linkGroups: [
                    {
                        icon: 'mdi-shield-crown-outline',
                        text: 'Admin',
                        permissions: 'Admin',
                        disabled: true,
                        links: [
                            { icon: 'mdi-shield-crown-outline', text: 'Admin', route: '/admin' },
                            { icon: 'mdi-shield-crown-outline', text: 'Fitness Account Admin', route: '/fitnessAccountAdmin' },
                        ]
                    },
                    {
                        icon: 'mdi-dumbbell',
                        text: 'Fitness',
                        permissions: 'Fitness Account',
                        disabled: true,
                        links: [
                            { icon: 'mdi-weight-lifter', text: 'Fitness Account', route: '/fitnessAccount' },
                            { icon: 'mdi-arm-flex', text: 'Workout', route: '/workout' },
                        ]

                    },
                    {
                        icon: 'mdi-scoreboard-outline',
                        text: 'Mahjong',
                        permissions: 'Mahjong',
                        disabled: true,
                        links: [
                            { icon: 'mdi-scoreboard-outline', text: 'Mahjong', route: '/mahjong' },
                        ]
                    },
                    {
                        icon: 'mdi-folder-image',
                        text: 'Portfolio Demo',
                        permissions: 'Portfolio',
                        disabled: true,
                        links: [
                            { icon: 'mdi-image-album', text: 'Portfolio', route: '/portfolio' },
                            { icon: 'mdi-image-album', text: 'Portfolio Admin', route: '/portfolioAdmin' },
                        ]
                    },
                    {
                        icon: 'mdi-dice-d20',
                        text: 'Unreleased TTRPG',
                        permissions: 'RPG',
                        disabled: true,
                        links: [
                            { icon: 'mdi-account-box', text: 'Character Sheet', route: '/charactersheet' },
                            { icon: 'mdi-wizard-hat', text: 'DM Page', route: '/DM' },
                        ]
                    },
                ],
                links: [
                    { icon: 'mdi-home', text: 'Home', route: '/', disabled: false, permissions: '' },
                    { icon: 'mdi-dice-d20', text: 'Initiative Roller', route: '/initiative', disabled: false, permissions: '' },
                    { icon: 'mdi-dance-ballroom', text: 'Dances', route: '/dance', disabled: false, permissions: '' },
                ],
                drawer: false,
                userSignedIn: this.$signedIn
            }
        },
        async created() {
            //this.links.push({ icon: 'mdi-file-document', text: 'Wiki', route: '/wiki' })
            if (await this.checkAdmin(null)) {
                let pages = await this.getAdminPages()
                pages.forEach(p => {
                    this.links.filter(l => { return l.permissions == p.name }).forEach(l => { l.disabled = false })
                    this.linkGroups.filter(g => { return g.permissions == p.name }).forEach(g => { g.disabled = false })
                })
            }
        },
        methods: {
            generalDialogFunction() {
                if (this.generalDialog.buttonType) {
                    if (this.generalDialog.buttonType == 'signOut') {
                        this.generalDialog.show = false
                        this.signOut()
                    }
                }
            },
            signOut() {
                localStorage.removeItem('signin')
                location.reload();
            },
            signOutConfirm() {
                this.generalDialog = {
                    buttonText: 'Sign Out',
                    buttonType: 'signOut',
                    html: '',
                    show: true,
                    text: 'Sign out of Google account, you may lose any work on this page?',
                    title: 'Sign Out'
                }
            }
        }
    }
</script>