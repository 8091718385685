<template>
    <DanceComponent></DanceComponent>
</template>

<script>
    import DanceComponent from '../components/DanceComponent'

    export default {
        name: 'DanceView',
        components: {
            DanceComponent
        }
    }
</script>