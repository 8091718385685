<template>
    <div>
        <v-app-bar dark color="secondary">
            <v-spacer></v-spacer>

            <v-switch label="Dark Theme"
                      v-model="$vuetify.theme.dark"
                      @click="saveDarkTheme"
                      inset></v-switch>
        </v-app-bar>

        <v-dialog v-model="generalDialog.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey">
                    {{generalDialog.title}}
                </v-card-title>

                <v-card-text>
                    {{generalDialog.text}}
                    <div v-html="generalDialog.html"></div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-end">
                    <v-btn v-if="generalDialog.buttonText && generalDialog.buttonType" @click="generalDialogFunction">{{generalDialog.buttonText}}</v-btn>
                    <v-btn color="secondary"
                           @click="generalDialog.show = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'FooterSection',
        mounted() {
            const theme = localStorage.getItem('darkTheme')
            if (theme) {
                this.$vuetify.theme.dark = theme === 'true'
            } else {
                this.saveDarkTheme()
            }
        },
        methods: {
            saveDarkTheme() {
                localStorage.setItem('darkTheme', this.$vuetify.theme.dark)
            },
            signOut() {
                localStorage.removeItem('signin')
                location.reload();
            }
        }
    }
</script>