<template>
    <v-app>
        <NavbarSection></NavbarSection>

        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>

        <FooterSection></FooterSection>
    </v-app>
</template>

<script>
    import FooterSection from '@/components/FooterSection'
    import NavbarSection from '@/components/NavbarSection'

    export default {
        name: 'App',
        components: {
            FooterSection,
            NavbarSection
        },
        data: () => ({
            //
        }),
    };
</script>