<template>
    <div class="text-center">
        <iframe src="https://calendar.google.com/calendar/embed?src=8e13e4916a34974dcda4db58fcef350df02a641eb2e83b389469ca6a8ac113e9%40group.calendar.google.com&ctz=America%2FChicago" style="border: 0; width: 100%;" height="600" frameborder="0" scrolling="yes"></iframe>
    </div>
</template>

<script>
    export default {
        name: 'DanceComponent',
    }
</script>