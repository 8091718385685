<template>
    <CharacterSheet></CharacterSheet>
</template>

<script>
    import CharacterSheet from '../components/CharacterSheet'

    export default {
        name: 'CharacterSheetView',

        components: {
            CharacterSheet
        },
    }
</script>